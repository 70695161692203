import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngStoDevelopment = loadable(() => import("../components/StoDevelopment/english.js"));
const ArabicStoDevelopment = loadable(() => import("../components/StoDevelopment/arabic.js"));
const ChineseStoDevelopment = loadable(() => import("../components/StoDevelopment/chinese.js"));

const schema = {
    "@context": "http://schema.org/",
    "@graph": [
        {
            "@type": "product",
            "image": "https://softtik.com/images/nft-collection/invest-section-17.webp",
            "name": "Security Token Offering Development Company",
            "description": "Softtik is a top STO development company developing data-driven STO campaigns to collect funding for blockchain-enabled businesses and startups.",
            "brand": "Softtik Technologies",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.7",
                "reviewCount": "107"
            }
        },
        {
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "What is STO in Blockchain?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>An STO, also known as a Security Token Offering, is a blockchain-powered digital token that represents a stake in an asset. STOs enable digital funding while adhering to government regulations.</p>"
                }
            }, {
                "@type": "Question",
                "name": "How much does it cost to create a security token?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>The cost of developing a security token depends on many factors and your requirements. Reach us at <b>Softtik Technologies</b> to submit your requirements and know the estimated cost.</p>"
                }
            }, {
                "@type": "Question",
                "name": "How do I register for STO?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>STO is registered with the Securities and Exchange Commission of the country. The registration process may vary from country to country.</p>"
                }
            }
            ]
        }
    ]
}

export class StoDevelopment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "English",
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');

        if (region == 'ae') this.setState({ lang: "Arabic" });
        // else if (region == 'sa') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/sto-development-services/"
                        title="STO Development Company - Softtik Technologies"
                        thumbnail="https://softtik.com/images/nft-collection/invest-section-17.webp"
                        description="Softtik is a top STO development company developing data-driven STO campaigns to collect funding for blockchain-enabled businesses and startups."
                    />
                    <main>
                        <div id="wrapper">
                            <div className={`collection-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicStoDevelopment />
                                    : lang == "China"
                                        ? <ChineseStoDevelopment />
                                        : <EngStoDevelopment />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default StoDevelopment;